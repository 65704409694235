<template>
  <div class="c-password-reset">
    <n-loader :loading="$var('load')"></n-loader>
    <c-verify-code v-if="show" :show.sync="show" :state.sync="isSuccess" :iin="user.iin" phone="recover" />
    <div class="container">
      <h3>Смена пароля</h3>
      <div class="input-place">
        <n-input class="password-input" placeholder="Ввведите старый пароль" type="password" v-bind="$form.input('oldPassword')" />
        <n-input class="password-input" placeholder="Ввведите новый пароль" type="password" v-bind="$form.input('newPassword')" />
        <n-input class="password-input" placeholder="Повторите новый пароль" type="password" v-bind="$form.input('confirmPassword')" />
      </div>
    </div>
    <div class="button-place">
      <n-button class="user-default-button" @click="sendCode">Сохранить</n-button>
    </div>
    <div class="button-place">
      <n-button class="user-default-button" @click="closeForm">Назад</n-button>
    </div>
  </div>
</template>

<script>
import imgBackrow from 'assets/backrow.svg'
import imgClose from 'assets/close-icon.svg'

export default {
  name: 'CPasswordReset',
  data() {
    return {
      isBtnDisabled: true,
      user: $app.auth.user(),
      show: false,
      isSuccess: false,

      imgBackrow,
      imgClose,
    }
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.reset()
      }
    },
  },
  created() {
    this.$form.init({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    this.$form.rules({
      oldPassword: [ 'required', ],
      newPassword: [ 'required', ],
      confirmPassword: [ 'required', ],
    })
  },
  methods: {
    reset() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.users.editPassword($app.auth.user().id, this.$form.get()).then(() => {
          $app.auth.logout()
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    sendCode() {
      this.show = true
    },
    closeForm() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-password-reset {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: black;
    margin: 0;
    text-align: center;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .input-place {
    margin-top: 50px;
    .password-input {
      margin-bottom: 20px;
      ::v-deep .n-content {
        border: none;
        border-bottom: 1px solid var(--secondary);
      }
    }
  }
  .button-place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    .user-default-button {
      width: 200px;
      height: 44px;
      background-color: var(--primary);
      border-radius: 8px;
      color: #ffffff;
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}
</style>
