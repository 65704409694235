<template>
  <div class="c-days-before">
    <div class="empty-row">
      <div class="color-row" :style="{'right': percent+'%'}" />
      <div class="bubble-days" :style="{'right': percent+'%'}">
        {{ daysBefore }}
      </div>
    </div>
    <h3>Дней до зарплаты</h3>
  </div>
</template>

<script>

export default {
  name: 'CDaysBefore',
  data() {
    return {
      currentDay: new Date().getDate(),
      daysCount: 33 - new Date(new Date().getFullYear(), new Date().getMonth(), 33).getDate(),
      daysBefore: 0,
      percent: 0,
    }
  },
  watch: {},
  created() {
    this.calc()
  },
  methods: {
    calc() {
      this.daysBefore = this.daysCount - this.currentDay
      this.percent = 100 - Math.round(this.currentDay / this.daysCount * 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-days-before {
  width: 100%;

  .empty-row {
    max-width: 370px;
    position: relative;
    background-color: #E5E6E8;
    height: 10px;
    border-radius: 8px;
    margin: 0 auto;

    .color-row {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      border-radius: 8px;
      background-color: var(--primary);
    }

    .bubble-days {
      position: absolute;
      top: -7px;
      bottom: 0;
      color: #ffffff;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      background-color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

  }

  h3 {
    text-align: center;
    font-weight: bold;
    color: #374151;
    font-size: 1.1em;
  }
}
</style>
