<template>
  <div class="c-phone-verify">
    <c-frame v-if="show" :url="frameUrl" :show.sync="show" />
    <div class="content">
      <n-loader :loading="$var('load')" />
      <div class="image-place">
        <img :src="imgPhoneVerify" alt="">
      </div>
      <div class="text">
        <h3>Подтверждение!</h3>
        <p>Введите 4-х значный код отправленный на номер телефона</p>
      </div>
      <c-code-input :code.sync="code" />
      <div class="lower-text">
        <p>Мне не пришел код!</p>
        <p>Отправить повторно</p>
      </div>
      <div class="button-place">
        <n-button type="submit" class="user-default-button" :disabled="isDisabled" @click="close()">Подтвердить</n-button>
      </div>
    </div>
  </div>
</template>

<script>
import imgPhoneVerify from 'assets/phone-verify.svg'
export default {
  name: 'CPhoneVerify',
  props: {
    amount: {
      required: true,
    },
    toCardsId: {
      required: true,
    },
  },
  data() {
    return {
      code: '',
      isDisabled: true,
      payData: [],
      user: $app.auth.user(),
      showAfterPay: false,
      sum: 0,
      isSuccess: false,
      show: false,
      frameUrl: '',

      imgPhoneVerify,
    }
  },
  watch: {
    code() {
      if (this.code.length === 4) {
        this.isDisabled = false
      }
    },
    show() {
      if (!this.show) {
        $app.store.action('app.updateUser').finally(() => {
          this.$router.push({ name: 'mobile.index', })
        })
      }
    },
  },
  methods: {
    close() {
      if (this.code.length === 4) {
        this.$var('load', true)
        $api.users.checkCode($app.auth.user().id, { code: this.code, }).then((response) => {
          if (response.data.content.isAvailable) {
            $api.payment.doPay(this.user.id, {
              amount: this.amount,
              commission: this.amount*0.15,
              toCardsId: this.toCardsId+'',
            }).then((response) => {
              this.frameUrl = response.data.content.paymentUrl
              this.show = true
            }).finally(() => {
              this.$var('load', false)
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-phone-verify {
  z-index: 11;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .content {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 24px;
    .image-place {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .text {
      h3 {
        text-align: center;
        margin: 30px 0 5px 0;
      }
      p {
        margin: 0 0 30px 0;
        text-align: center;
      }
    }
    .lower-text {
      p {
        margin: 0;
        font-size: 1em;
        &:last-child {
          color: var(--primary);
        }
        &:first-child {
          color: #9CA3AF;
        }
      }
    }
    .button-place {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      .user-default-button {
        width: 270px;
        height: 44px;
        background-color: var(--primary);
        border-radius: 8px;
        color: #ffffff;
        font-size: 1.1em;
        font-weight: bold;
      }
    }
  }
}
</style>
