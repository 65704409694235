export default {
  app: {
    state: {
      test: {},
    },
    mutations: {
      setTest(state, value) {
        state.test = value
      },
    },
    actions: {
      updateUser() {
        return $api.auth.info().then((response) => {
          $app.auth.user(response.data.content.user)
        })
      },
    },
    namespaced: true,
  },
}
