<template>
  <div class="c-top-desk">
    <div class="left-side">
      <div v-if="!!backroute" class="back-row-button" @click="$router.push({name: backroute})">
        <div class="back-row">
          <img :src="imgBackRow" alt="">
        </div>
      </div>
      <div v-show="!onlyBackroute" class="input-place">
        <div class="search-icon">
          <img :src="imgSearch" alt="">
        </div>
        <n-input class="top-desk-input" placeholder="Поиск" />
      </div>
    </div>
    <div class="right-side">
      <div class="notify">
        <img :src="imgNotify" alt="">
      </div>
      <div class="company">
        <n-loader :loading="$var('load')" />
        <div class="logo">
          <img :src="imgLogo" alt="">
        </div>
        <div class="title">
          {{ company.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgSearch from 'assets/search-icon.svg'
import imgLogo from 'assets/logo.svg'
import imgNotify from 'assets/notify-icon.svg'
import imgBackRow from 'assets/back-row.svg'

export default {
  name: 'CTopDesk',
  props: {
    backroute: {
      type: String,
      default: null,
    },
    onlyBackroute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      company: {},

      imgLogo,
      imgSearch,
      imgNotify,
      imgBackRow,
    }
  },
  watch: {},
  created() {
    this.loadCompany()
  },
  methods: {
    loadCompany() {
      this.$var('load', true)
      $api.companies.get($app.auth.user().companyId).then((response) => {
        this.company = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-top-desk {
  position: fixed;
  z-index: 10;
  height: 50px;
  left: 230px;
  right: 0;
  border-bottom: 0;
  top: 0;
  padding: 50px 40px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  .left-side {
    display: flex;
    align-items: center;
    .input-place {
      display: flex;
      align-items: center;
    }

    .search-icon {
      background-color: #FAFBFD;
      height: 40px;
      border-radius: 12px 0 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      padding: 15px 0 17px 5px;
    }
    .top-desk-input {
      ::v-deep .n-content {
        background-color: #FAFBFD;
        padding-left: 5px;
        border: none;
        min-width: 490px;
        height: 40px;
        border-radius: 0 12px 12px 0;
      }
    }
    .back-row-button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FAFBFD;
      border-radius: 12px;
      margin-right: 10px;
      .back-row {
        width: 24px;
        height: 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .right-side {
    display: flex;
    align-items: center;

    .notify {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .company {
      margin-left: 20px;
      display: flex;
      align-items: center;
      position: relative;
      min-width: 170px;
      .logo {
        width: 44px;
        height: 44px;
         img {
           width: 100%;
           height: 100%;
           object-fit: contain;
         }
      }
      .title {
        color: #414D55;
        font-size: 1.2em;
        margin-left: 8px;
      }
    }
  }

}
</style>
