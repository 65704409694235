<template>
  <div class="c-sidebar">
    <div class="sidebar-links">
      <div class="logo-place">
        <div class="image-place">
          <img :src="imgLogo" alt="">
        </div>
        <div class="title">
          ProsperPay
        </div>
      </div>
      <n-link class="sidebar-link" :to="{name:'desktop.index'}" :class="{'active': routeName === 'desktop.index'}">
        <img class="icon" :src="routeName === 'desktop.index' ? imgStatsActive : imgStats" alt="">
        <p :class="{'active': routeName === 'desktop.index'}">Статистика</p>
      </n-link>
      <n-link class="sidebar-link" :to="{name:'desktop.users'}" :class="{'active': routeName === 'desktop.users'}">
        <img class="icon" :src="routeName === 'desktop.users' ? imgUsersActive : imgUsers" alt="">
        <p :class="{'active': routeName === 'desktop.users'}">Пользователи</p>
      </n-link>
      <n-link class="sidebar-link" :to="{name:'support'}" :class="{'active': routeName === 'desktop.support'}">
        <img class="icon" :src="routeName === 'support' ? imgSupportActive : imgSupport" alt="">
        <p :class="{'active': routeName === 'support'}">Служба поддержки</p>
      </n-link>
      <n-link class="sidebar-link" :to="{name: 'faq'}" :class="{'active': routeName === 'desktop.faq'}">
        <img class="icon" :src="routeName === 'faq' ? imgFaqActive : imgFaq" alt="">
        <p :class="{'active': routeName === 'faq'}">F.A.Q.</p>
      </n-link>
      <n-button class="side-bar-logout" flat @click="$app.auth.logout()">Выйти</n-button>
    </div>
  </div>
</template>

<script>
import imgLogo from 'assets/logo.svg'
import imgFaq from 'assets/sidebar/faq.svg'
import imgStats from 'assets/sidebar/stats.svg'
import imgSupport from 'assets/sidebar/support.svg'
import imgUsers from 'assets/sidebar/users.svg'
import imgFaqActive from 'assets/sidebar/faq-active.svg'
import imgStatsActive from 'assets/sidebar/stats-active.svg'
import imgSupportActive from 'assets/sidebar/support-active.svg'
import imgUsersActive from 'assets/sidebar/users-active.svg'

export default {
  name: 'CSidebar',
  data() {
    return {
      imgLogo,
      imgStats,
      imgFaq,
      imgSupport,
      imgUsers,
      imgStatsActive,
      imgFaqActive,
      imgSupportActive,
      imgUsersActive,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
  },
  watch: {},
  created() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.c-sidebar {
  position: fixed;
  z-index: 10;
  height: 100vh;
  left: 0;
  border-bottom: 0;
  bottom: 0;
  top: 0;
  padding: 5px 15px 0;
  min-width: 220px;
  background-color: #F1F5F8;

  .logo-place {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image-place {
      width: 78px;
      height: 78px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-size: 1.7em;
      color: var(--primary);
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .sidebar-links {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;

    .sidebar-link {
      text-decoration: none;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      min-width: 170px;
      border-radius: 12px;
      margin-bottom: 15px;

      &.active {
        background-color: var(--primary);
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        color: var(--secondary);
        font-size: 1.3em;
        min-width: 125px;
        max-width: 145px;
        margin-left: 15px;

        &.active {
          color: #ffffff;
        }
      }
    }

    .side-bar-logout {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
    }
  }
}
</style>
