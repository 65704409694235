export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '', name: 'login', icon: 'genderless', component: () => import('pages/auth/Index'), },
      { path: '/recover', name: 'recover', icon: 'genderless', component: () => import('pages/auth/recover-password/Index'), },
      { path: '/recover/finish', name: 'recover.finish', icon: 'genderless', component: () => import('pages/auth/recover-password/Recover'), },
      { path: '/register', name: 'register', icon: 'genderless', component: () => import('pages/auth/registration/Index'), },
      { path: '/register/finish', name: 'register.finish', icon: 'genderless', component: () => import('pages/auth/registration/Finish'), },
    ], },
    { path: '', component: () => import('layouts/admin/Index'), children: [
      { path: '/desktop/main', name: 'desktop.index', icon: 'genderless', component: () => import('pages/desktop/index/Index'), },
      { path: '/desktop/user/:id', name: 'desktop.user', icon: 'genderless', component: () => import('pages/desktop/user/Index'), },
      { path: '/desktop/users', name: 'desktop.users', icon: 'genderless', component: () => import('pages/desktop/users/Index'), },
    ], },
    { path: '', component: () => import('layouts/user/Index'), children: [
      { path: '/mobile/main', name: 'mobile.index', icon: 'genderless', component: () => import('pages/mobile/index/Index'), },
      { path: '/mobile/payment', name: 'mobile.payment', icon: 'genderless', component: () => import('pages/mobile/payment/Index'), },
      { path: '/mobile/profile', name: 'mobile.profile', icon: 'genderless', component: () => import('pages/mobile/profile/Index'), },
      { path: '/mobile/card/choise', name: 'mobile.card.choise', icon: 'genderless', component: () => import('pages/mobile/card-choise/Index'), },
      { path: '/mobile/card/before', name: 'mobile.beforePay', icon: 'genderless', component: () => import('pages/mobile/before-pay/Index'), },
      { path: '/mobile/profile/my-data', name: 'mobile.profile.myData', icon: 'genderless', component: () => import('pages/mobile/my-data/Index'), },
      { path: '/mobile/profile/my-cards', name: 'mobile.profile.myCards', icon: 'genderless', component: () => import('pages/mobile/my-cards/Index'), },
      { path: '/mobile/payment/after', name: 'mobile.payment.after', icon: 'genderless', component: () => import('pages/mobile/after-pay/Index'), },
      { path: '/mobile/transactions', name: 'mobile.transactions', icon: 'genderless', component: () => import('pages/mobile/transactions/Index'), },
      { path: '/mobile/profile/support', name: 'mobile.profile.support', icon: 'genderless', component: () => import('pages/mobile/support/Index'), },
      { path: '/mobile/profile/security', name: 'mobile.profile.security', icon: 'genderless', component: () => import('pages/mobile/security/Index'), },
      { path: '/mobile/profile/support/faqs', name: 'mobile.profile.support.faqs', icon: 'genderless', component: () => import('pages/mobile/faqs/Index'), },
      { path: '/mobile/profile/support/applications', name: 'mobile.profile.support.applications', icon: 'genderless', component: () => import('pages/mobile/applications/Index'), },
    ], },
  ], },
]
