<template>
  <div class="c-frame">
    <div class="frame-box">
      <iframe class="frame" :src="url"></iframe>
      <n-button class="frame-button" flat @click="closeForm">Назад</n-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CFrame',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  created() {},
  methods: {
    closeForm() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-frame {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  background-color: #FFFFFF;
  .frame-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .frame-button {
      margin: 25px 20px 0 20px;
      width: 90%;
      background-color: var(--primary);
      height: 44px;
      border-radius: 12px;
      ::v-deep .content {
        color: #FFFFFF;
      }
    }
    .frame {
      width: 100%;
      height: 90%;
      border: none;
    }
  }
}
</style>
