/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    register: (iin, phone, data) => $app.api.post([ 'auth/register**', iin, phone, ]).data(data),
    recover: (iin, data) => $app.api.post([ 'auth/recover*', iin, ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
    checkData: (data) => $app.api.post([ 'auth/check-data', ]).data(data),
    sendCode: (iin, phone) => $app.api.post([ 'sms/send-code**', iin, phone, ]),
    checkCode: (iin, data) => $app.api.post([ 'sms/check-code*', iin, ]).data(data),
  }
  
  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
  }
  
  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    editPassword: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
    sendCode: (id) => $app.api.post([ 'users*/send-code', id, ]),
    checkCode: (id, data) => $app.api.post([ 'users*/check-code', id, ]).data(data),
  }

  transactions = {
    check: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions/check', companyId, userId, ]).data(data),
    create: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions', companyId, userId, ]).data(data),
    getList: (companyId, userId) => $app.api.get([ 'companies*/users*/transactions', companyId, userId, ]),
  }
  
  payment = {
    doPay: (userId, data) => $app.api.post([ 'payment/do*', userId, ]).data(data),
    saveCard: (userId) => $app.api.post([ 'payment/save-card', userId, ]),
    getCards: (userId) => $app.api.post([ 'payment/get-cards', userId, ]),
    deleteCard: (cardsId) => $app.api.post([ 'payment/delete-card*', cardsId, ]),
  }
  
  contacts = {
    get: () => $app.api.get([ 'contacts', ]),
  }
  
  application = {
    getByUser: (userId) => $app.api.get([ 'applications/users*', userId, ]),
    create: (data) => $app.api.post([ 'applications  ', ]).data(data),
  }
  
  faqs = {
    get: () => $app.api.get([ 'faqs', ]),
  }
}
