<template>
  <div class="c-code-input">
    <div class="code-inputs">
      <input id="0" v-model="char1" maxlength="1" type="tel" :class="{'filled': !!char1}">
      <input id="1" v-model="char2" maxlength="1" type="tel" :class="{'filled': !!char2}">
      <input id="2" v-model="char3" maxlength="1" type="tel" :class="{'filled': !!char3}">
      <input id="3" v-model="char4" maxlength="1" type="tel" :class="{'filled': !!char4}">
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCodeInput',
  data() {
    return {
      code: '',
      char1: null,
      char2: null,
      char3: null,
      char4: null,
      focusNum: 0,
    }
  },
  watch: {
    char1() {
      if (this.char1) {
        this.char1 = this.checkSymbol(this.char1, '1')
        document.getElementById('1').readOnly = false
        document.getElementById('1').focus()
      } else {
        document.getElementById('0').focus()
      }
    },
    char2() {
      if (this.char2) {
        this.char2 = this.checkSymbol(this.char2, '2')
        document.getElementById('2').readOnly = false
      } else {
        document.getElementById('0').focus()
      }
    },
    char3() {
      if (this.char3) {
        this.char3 = this.checkSymbol(this.char3, '3')
        document.getElementById('3').readOnly = false
      } else {
        document.getElementById('1').focus()
      }
    },
    char4() {
      if (this.char4) {
        this.char4 = this.checkSymbol(this.char4, '4')
      } else {
        document.getElementById('2').focus()
      }
    },
    focusNum() {
      if (this.focusNum !== null) {
        document.getElementById(this.focusNum+'').focus()
        this.focusNum = null
      }
    },
  },
  created() {},
  methods: {
    checkSymbol(value, nextId) {
      if (Number(value) || value === '0') {
        this.writeCode(Number(nextId), value)

        if (nextId !== '4') {
          document.getElementById(nextId).focus()
        } else {
          this.$emit('update:code', this.code)
        }
        return value
      } else {
        return null
      }
    },
    writeCode(index, char) {
      if (index === 1) {
        this.code = ''
      }
      this.code = this.code.substr(0, index-1) + char + this.code.substr(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-code-input {
  display: flex;
  flex-direction: column;
  align-items: center;


  .code-inputs {
    margin: 0 0 18px;
    display: flex;
    align-items: center;
    input {
      width: 54px;
      height: 54px;
      padding: 5px;
      margin: 0 3px;
      font-weight: 300;
      background-color: #ffffff;
      color: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 12px;
      text-align: center;
      font-size: 1.7em;
      &:placeholder-shown { border-bottom: 3px solid #E3000F; }
      &:focus-visible { outline: none; }
      &:focus { outline: none; }
      &::placeholder { opacity: 0; }
      &.filled {
        background-color: var(--secondary);
        color: #ffffff;
      }
    }
  }
}
</style>
