import CSidebar from 'components/side-bar/Index'
import CTopDesk from 'components/top-desk/Index'
import CMobileAppbar from 'components/mobile-app-bar/Index'
import CCircleStats from 'components/circle-stats/Index'
import CDaysBefore from 'components/days-before/Index'
import CGoBack from 'components/go-back/Index'
import CPhoneVerify from 'components/phone-verify/Index'
import CCodeInput from 'components/code-input/Index'
import CUserEdit from 'components/user-edit/Index'
import CModalContacts from 'components/modal-contacts/Index'
import CNewApplication from 'components/new-application/Index'
import CPasswordReset from 'components/reset-password/Index'
import CModalMessage from 'components/modal-message/Index'
import CEmpty from 'components/empty/Index'
import CFrame from 'components/frame/Index'
import CVerifyCode from 'components/verify-code/Index'

export default {
  CSidebar,
  CTopDesk,
  CMobileAppbar,
  CCircleStats,
  CDaysBefore,
  CGoBack,
  CPhoneVerify,
  CCodeInput,
  CUserEdit,
  CModalContacts,
  CNewApplication,
  CPasswordReset,
  CModalMessage,
  CEmpty,
  CFrame,
  CVerifyCode,
}
