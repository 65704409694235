<template>
  <div class="c-mobile-appbar">
    <div class="appbar-links">
      <n-link class="appbar-link" :to="{name:'mobile.transactions'}">
        <img class="icon" :src="routeName === 'mobile.transactions' ? imgTransactionsActive : imgTransactions" alt="">
      </n-link>
      <n-link class="appbar-link" :to="{name:'mobile.index'}">
        <img class="icon" :src="routeName === 'mobile.index' ? imgHomeActive : imgHome" alt="">
      </n-link>
      <n-link class="appbar-link" :to="{name:'mobile.profile'}">
        <img class="icon" :src="routeName === 'mobile.profile' ? imgUserActive : imgUser" alt="">
      </n-link>
    </div>
  </div>
</template>

<script>
import imgTransactions from 'assets/mobile-appbar/mobile-transactions.svg'
import imgUser from 'assets/mobile-appbar/mobile-user.svg'
import imgHome from 'assets/mobile-appbar/mobile-home.svg'
import imgTransactionsActive from 'assets/mobile-appbar/mobile-transactions-active.svg'
import imgUserActive from 'assets/mobile-appbar/mobile-user-active.svg'
import imgHomeActive from 'assets/mobile-appbar/mobile-home-active.svg'

export default {
  name: 'CMobileAppbar',
  data() {
    return {
      imgHome,
      imgUser,
      imgTransactions,
      imgHomeActive,
      imgUserActive,
      imgTransactionsActive,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
  },
  watch: {},
  created() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.c-mobile-appbar {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 40px;
  right: 0;
  padding: 5px 15px 0;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #F9F9F9;

  .appbar-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    min-width: 315px;
    min-height: 75px;
    background-color: #FFFFFF;
    padding: 25px;
    width: 100%;
    border-radius: 20px;

    .appbar-link {
      text-decoration: none;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.active {
        background-color: var(--primary);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
