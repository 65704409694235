<template>
  <div class="c-go-back">
    <n-link :to="{name: backroute}">
      <div class="back-row">
        <img :src="imgBackrow" alt="">
      </div>
    </n-link>
    <h1 class="title">{{ title }}</h1>
  </div>
</template>

<script>
import imgBackrow from 'assets/backrow.svg'

export default {
  name: 'CGoBack',
  props: {
    backroute: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgBackrow,
    }
  },
}
</script>

<style lang="scss" scoped>
.c-go-back {
  display: flex;
  align-items: center;
  .back-row {
    width: 24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h1 {
    margin: 0 auto;
    font-size: 1.4em;
    color: #000000;
  }
}
</style>
