<template>
  <div class="c-empty">
    <h3>Пусто</h3>
  </div>
</template>

<script>

export default {
  name: 'CCircleStats',
}
</script>

<style lang="scss" scoped>
.c-empty {
  h3 {
    margin-top: 30vh;
    text-align: center;
    color: #A6A6A6;
  }
}
</style>
